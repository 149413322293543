import React, { useEffect, useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import Container from "../../container/container";
import * as StyledComponents from "./success.module.scss";

//material ui
import CircularProgress from "@mui/material/CircularProgress";

export default function SuccessInvoice() {
  const stripe = useStripe();
  const [Email, setEmail] = useState("");
  const [Total, setTotal] = useState(0);

  const [DisplaySpinner, setDisplaySpinner] = useState({ display: "flex" });
  const [DisplayMessage, setDisplayMessage] = useState({ display: "none" });

  //var to change the currency formats
  var formatter = new Intl.NumberFormat("fr-CA", {
    style: "currency",
    currency: "CAD",
  });

  //when page loads
  useEffect(() => {
    if (!stripe) {
      return;
    }
    run();
  }, [stripe]);

  //run metaPI() and PIfull
  async function run() {
    //get metadata
    let xmetaData = await metaPI();
    //set total
    setTotal(xmetaData.total);

    //get PI
    let xpaymentIntent = await PIfull();
    //setEmail
    setEmail(xpaymentIntent.description.toUpperCase());

    //Create new invoice in strapi
    newInvoice(xmetaData, xpaymentIntent);
  }

  //get metadata from payment intent
  async function metaPI() {
    let res;
    const paymentIntent = new URLSearchParams(window.location.search).get(
      "payment_intent"
    );
    if (!stripe) {
      return;
    }
    await fetch("https://www.wheels-api.com/stripe/retrieve-payment-intent", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        items: [
          {
            pi: paymentIntent,
          },
        ],
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data.metadata[0]);
        res = data.metadata[0];
      });
    return res;
  }

  //get payment intent full format
  async function PIfull() {
    let res;
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    await stripe
      .retrievePaymentIntent(clientSecret)
      .then(({ paymentIntent }) => {
        // console.log(paymentIntent);
        res = paymentIntent;
      });
    return res;
  }

  //creates invoice info in the api
  async function newInvoice(xmetaData, xpaymentIntent) {
    let piid = xpaymentIntent.id;
    let name = xpaymentIntent.shipping.name.toUpperCase();
    let email = xmetaData.email.toUpperCase();
    let phone = xpaymentIntent.shipping.phone;
    const day = new Date();
    let car = xmetaData.vehicle.toUpperCase();
    let subtot = xmetaData.subtotal;
    let discount = xmetaData.discount;
    let tps = xmetaData.tps;
    let tvq = xmetaData.tvq;
    let total = xmetaData.total;
    let link = document.URL;
    let metadata = xmetaData;
    let shipping = {
      name: xpaymentIntent.shipping.name.toUpperCase(),
      address: xpaymentIntent.shipping.address.line1.toUpperCase(),
      city: xpaymentIntent.shipping.address.city.toUpperCase(),
      state: xpaymentIntent.shipping.address.state.toUpperCase(),
      postal_code: xpaymentIntent.shipping.address.postal_code.toUpperCase(),
      phone: xpaymentIntent.shipping.phone,
      email: xmetaData.email.toUpperCase(),
    };

    //set wheels and tires in cart
    let arrayCart = JSON.parse(xmetaData.cart);
    let newCart = [];
    for (let i = 0; i < arrayCart.length; i++) {
      let xtire = await tireSkuInfo(arrayCart[i].sku);
      let xwheel = await wheelSkuInfo(arrayCart[i].sku);

      //if Tire
      if (xtire.length !== 0) {
        //console.log(xtire);
        let product = {
          sku: xtire[0].sku,
          description:
            xtire[0].brand.toUpperCase() +
            " " +
            xtire[0].model.toUpperCase() +
            " " +
            xtire[0].sectionWidth +
            "X" +
            xtire[0].aspectRatio +
            "R" +
            xtire[0].diameter,
          qty: parseInt(arrayCart[i].qty),
          price: parseFloat(arrayCart[i].price),
        };
        //console.log(product);
        newCart.push(product);
      }

      //if Wheel
      if (xwheel.length !== 0) {
        //console.log(xwheel);
        //pcd2
        let pcd2 = xwheel[0].pcd2;
        if (pcd2 !== null) {
          pcd2 = "| " + pcd2 + " ";
        } else {
          pcd2 = "";
        }

        //offset
        let offset = xwheel[0].offset;
        if (offset > 0) {
          offset = "+" + offset;
        }

        let product = {
          sku: xwheel[0].sku.toUpperCase(),
          description:
            xwheel[0].name.toUpperCase() +
            " " +
            xwheel[0].diameter +
            "X" +
            xwheel[0].width +
            " " +
            xwheel[0].pcd1 +
            " " +
            pcd2 +
            xwheel[0].cb +
            " " +
            offset,
          qty: parseInt(arrayCart[i].qty),
          price: parseFloat(arrayCart[i].price),
        };
        //console.log(product);
        newCart.push(product);
      }
    }

    //set environment fees
    if (xmetaData.tires > 0) {
      let product = {
        sku: "TP",
        description: "FRAIS ENVIRONEMENTAL",
        qty: parseInt(xmetaData.tires),
        price: 3,
      };
      //console.log(product);
      newCart.push(product);
    }

    //set tpms
    if (xmetaData.tpms > 0) {
      let product = {
        sku: "MX002R",
        description: "MAX SENSORS GEN 2 RUBBER",
        qty: parseInt(xmetaData.tpms),
        price: 49.95,
      };
      //console.log(product);
      newCart.push(product);
    }

    //set nuts
    if (xmetaData.nuts > 0) {
      let product = {
        sku: "TUNERKIT49",
        description: "TUNER NUTS KIT",
        qty: parseInt(xmetaData.nuts),
        price: 49.95,
      };
      //console.log(product);
      newCart.push(product);
    }

    //financial
    let financial = {
      subtotal: parseFloat(xmetaData.subtotal),
      discount: parseFloat(xmetaData.discount),
      newSubtotal: xmetaData.subtotal - xmetaData.discount,
      tps: parseFloat(xmetaData.tps),
      tvq: parseFloat(xmetaData.tvq),
      total: parseFloat(xmetaData.total),
    };
    newCart.push(financial);

    //console.log(newCart);
    let cart = newCart;

    fetch("https://www.wheels-api.com/invoices", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        piid: piid,
        name: name,
        email: email,
        phone: phone,
        date: day,
        selectedCar: car,
        subtotal: subtot,
        discount: discount,
        tps: tps,
        tvq: tvq,
        total: total,
        link: link,
        shipping: shipping,
        metadata: metadata,
        cart: cart,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        if (data.statusCode === undefined) {
          //send email
          fetch("https://www.wheels-api.com/emailBill", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              date: day,
              shipping: shipping,
              selectedCar: car,
              cart: cart,
            }),
          });
        }

        //hide loader and display good to go
        setDisplaySpinner({ display: "none" });
        setDisplayMessage({ display: "inline" });
      });
  }

  //get wheel product info
  async function wheelSkuInfo(xsku) {
    let res;
    let fetchURL = "https://www.wheels-api.com/wheels?sku_eq=" + xsku;
    await fetch(fetchURL)
      .then((res) => res.json())
      .then(
        (result) => {
          //console.log(result);
          res = result;
        },
        (error) => {
          console.log(error);
        }
      );
    return res;
  }

  //get tire product info
  async function tireSkuInfo(xsku) {
    let res;
    let fetchURL = "https://www.wheels-api.com/tires?sku_eq=" + xsku;
    await fetch(fetchURL)
      .then((res) => res.json())
      .then(
        (result) => {
          //console.log(result);
          res = result;
        },
        (error) => {
          console.log(error);
        }
      );
    return res;
  }

  return (
    <Container>
      <div className={StyledComponents.spinnerContainer} style={DisplaySpinner}>
        <CircularProgress size={100} sx={{ color: "var(--darkgreen)" }} />
        <p>veuillez rester sur cette page</p>
      </div>
      <article
        className={StyledComponents.messageContainer}
        style={DisplayMessage}
      >
        <p>&#x2714;</p>
        <p>Merci d'avoir commandé! </p>
        <p>Votre commande a été enregistrée avec succès.</p>
        <p>
          Nous vous enverrons votre facture à votre adresse électronique dans
          quelques minutes.
        </p>
        <p>{formatter.format(Total)}</p>
        <p>{Email}</p>
      </article>
    </Container>
  );
}
