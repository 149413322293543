import React from "react";
import { PageLayout } from "../components/layout/layout";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
//import DisplayInvoice from "../components/invoice/invoice";
import SuccessInvoice from "../components/invoice/success/success";
// markup
const BillPage = () => {
  const stripePromise = loadStripe(
    "pk_live_51K46sCCETZZkd7IPswwzQXh9jKuU2Jjdd6gysSsbVo35CIFBLcHFiqvR8pY5ISuZRvnA2uW24QyGkpq9QOAWVmso00OUM7Cmq6"
  );

  if (typeof window !== "undefined") {
    //console.log("CLEAR LOCAL STORAGE");
    localStorage.clear();
  }

  return (
    <PageLayout>
      <Elements stripe={stripePromise}>
        <SuccessInvoice />
      </Elements>
    </PageLayout>
  );
};

export default BillPage;
